import useUserAddForm from 'modules/users/hooks/useUserAddForm';
import UserForm from '../UserForm/UserForm';
import { ErrorMessage, Form, Formik } from 'formik';
import { Button, CircularProgress, HelpText, Label } from '@hyperflake/react-ui-library';
import { UploadIcon } from 'modules/shared/icons';
import { FormikInput, FormikSelect, RequiredMark } from 'modules/shared/features/formik';
import { statusOption, userTypeOption } from 'modules/users/helpers/user.util';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const UserAddForm = () => {
    const addUserForm = useUserAddForm();
    const navigate = useNavigate();

    const handleSubmit = async (values: any) => {
        try {
            const data = await addUserForm.submit(values);
            toast.success('User Added Successfully');
            navigate('/users');
            // onAddUser(data);
            // onSubmit(data._id);
        } catch (e: any) {
            console.log(e);
            toast.error('Oops something went wrong.');
        }
    };
    return (
        <Formik
            initialValues={addUserForm.initialValues}
            //enableReinitialize
            validationSchema={addUserForm.schema}
            validateOnChange={false}
            onSubmit={handleSubmit}
        >
            {({ setFieldValue, values, isValidating }) => (
                <Form>
                    <div className="mt-4 ">
                        {/* name */}
                        <div className="">
                            <Label>
                                Name <RequiredMark />
                            </Label>
                            <FormikInput type="text" name="name" />
                            <HelpText variant={'error'}>
                                <ErrorMessage name="name" />
                            </HelpText>
                        </div>

                        {/* phoneNumber */}
                        <div className="">
                            <Label>
                                Phone Number <RequiredMark />
                            </Label>
                            <FormikInput type="number" name="phoneNumber" />
                            <HelpText variant={'error'}>
                                <ErrorMessage name="phoneNumber" />
                            </HelpText>
                        </div>
                        {/* whatsapp Number */}
                        <div className="">
                            <Label>
                                Whatsapp Number <RequiredMark />
                            </Label>
                            <FormikInput type="number" name="whatsappNumber" />
                            <HelpText variant={'error'}>
                                <ErrorMessage name="whatsappNumber" />
                            </HelpText>
                        </div>
                        {/* Roles */}
                        <div>
                            <Label>
                                Roles <RequiredMark />
                            </Label>
                            <div className="flex gap-2">
                                <FormikSelect
                                    name="roles"
                                    options={userTypeOption}
                                    // disabled={formik.values.pointedTo !== ''}
                                />
                            </div>
                            <HelpText variant={'error'}>
                                <ErrorMessage name="role" />
                            </HelpText>
                        </div>
                        {/* status */}
                        <div>
                            <Label>
                                Status <RequiredMark />
                            </Label>
                            <div className="flex gap-2">
                                <FormikSelect
                                    name="status"
                                    options={statusOption}
                                    // disabled={formik.values.pointedTo !== ''}
                                />
                            </div>
                            <HelpText variant={'error'}>
                                <ErrorMessage name="role" />
                            </HelpText>
                        </div>

                        {/* company name */}
                        <div className="">
                            <Label>Company Name</Label>

                            <FormikInput type="text" name="company.name" />
                            <HelpText variant={'error'}>
                                <ErrorMessage name="company.name" />
                            </HelpText>
                        </div>
                        <div className="">
                            <Label>Company Address</Label>

                            <FormikInput type="text" name="company.address" />
                            <HelpText variant={'error'}>
                                <ErrorMessage name="company.address" />
                            </HelpText>
                        </div>
                    </div>

                    <Button
                        //   disabled={addUserForm.isSubmitting || isValidating}
                        type="submit"
                        size={'lg'}
                        className="mt-8 w-28"
                    >
                        {addUserForm.isSubmitting ? (
                            <CircularProgress size={20} color={'inherit'} />
                        ) : isValidating ? (
                            'Validating'
                        ) : (
                            'Submit'
                        )}
                    </Button>
                </Form>
            )}
        </Formik>
        // <UserForm
        //   title="Add User"
        //   initialValues={userFormData.initialValues}
        //   validationSchemes={userFormData.schema}
        //   onSubmit={handleSubmit}
        // />
    );
};

export default UserAddForm;
