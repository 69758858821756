// import AuthGuard from "modules/shared/hocs/AuthGuard/AuthGuard";
// import ErrorBoundary from "modules/shared/hocs/ErrorBoundary/ErrorBoundary";
// import MainLayout from "modules/shared/layouts/MainLayout/MainLayout";
import AppLayout from './AppLayout';

// import Login from "@module/auth/Login";
// import SigninLogs from 'modules/signin-logs/components/SigninLogs/SigninLogs';
// import { PermissionEnum } from "@api/enum/permission.enum";
// import { ClientList } from "modules/clients";
// import NotFound from "modules/shared/hocs/NotFound/NotFound";
// import PermissionGuard from "modules/shared/hocs/PermissionGuard/PermissionGuard";
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
// import PermissionGuard from "./modules/shared/hocs/PermissionGuard/PermissionGuard";
import Login from 'modules/auth/Login';
// import MainLayout from "modules/shared/layouts/mainLayout/MainLayout";
import ErrorBoundary from 'modules/shared/hocs/ErrorBoundary/ErrorBoundary';
import NotFound from 'modules/shared/hocs/NotFound/NotFound';
import { UserAddForm, UserEditForm, UserList } from 'modules/users';
import AuthGuard from 'modules/shared/hocs/AuthGuard/AuthGuard';
import MainLayout from 'modules/shared/layouts/MainLayout/MainLayout';
import { ListingList } from 'modules/listing';

const router = createBrowserRouter([
    {
        path: '/',
        element: <AppLayout />,
        errorElement: <ErrorBoundary />,
        children: [
            {
                path: '/login',
                element: <Login />,
            },
            {
                path: '/',
                element: <AuthGuard page={<MainLayout />} />,
                //
                // element: <MainLayout />,
                children: [
                    {
                        index: true,
                        element: <Navigate to={'/dashboard'} replace />,
                    },
                    {
                        path: 'dashboard',
                        element: <h2 className="">This is a dashboard</h2>,
                        // element: (
                        //   <PermissionGuard
                        //     page={<ClientList />}
                        //     permissions={[PermissionEnum.CLIENT_READ]}
                        //   />
                        // ),
                    },
                    {
                        path: 'users',
                        element: <UserList />,
                        // children: [
                        //   {
                        //     path: "add-user",
                        //     element: <UserAddForm />,
                        //   },
                        // ],
                        // element: (
                        //   <PermissionGuard
                        //     page={<ClientList />}
                        //     permissions={[PermissionEnum.CLIENT_READ]}
                        //   />
                        // ),
                    },
                    {
                        path: 'users/add',
                        element: <UserAddForm />,
                    },
                    {
                        path: 'users/:userId/edit',
                        element: <UserEditForm />,
                    },
                    {
                        path: 'listings',
                        element: <ListingList />,
                    },
                ],
            },
            {
                path: '/404',
                element: <NotFound />,
            },
        ],
    },
]);

const AppRouter = () => {
    return <RouterProvider router={router} />;
};

export default AppRouter;
