// import { authActions } from '@store/auth';
// import store from '@store/index';
import axios from 'axios';
import { getAuthTokenFromStorage } from 'modules/shared/helpers/auth.utils';

// axios instance for making requests
const http = axios.create();

//request interceptor for adding token
http.interceptors.request.use((config) => {
    // const token = store.getState().auth.token;
    const token = getAuthTokenFromStorage();

    if (token) {
        // add token to request headers
        config.headers['authorization'] = `Bearer ${token}`;
    }

    return config;
});

http.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.status === 401) {
            // store.dispatch(authActions.logout());
            // window.location.href = `/login`;
        }

        return Promise.reject(error.response);
    }
);

export default http;
