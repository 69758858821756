import { IListing } from '@api/models/listing.model';
import { PageHeading, PageTemplate } from 'modules/shared/features/content';
import React from 'react';
import ListingCard from '../ListingCard/ListingCard';
import useListingListData from 'modules/listing/hooks/useListingListData';
import { Table } from '@hyperflake/react-ui-library';
import { EventBlocker } from '@hyperflake/react-ui-library/eventBlocker/index.js';
import { EditIcon } from 'modules/shared/icons';

const ListingList = () => {
    const listQueryData = useListingListData();
    return (
        <div>
            <PageTemplate>
                <div className="flex justify-between">
                    <PageHeading>Listing</PageHeading>

                    {/* {hasPermission(PermissionEnum.TIMELAPSE_CAMERA_CREATE) && (
          <Button onClick={() => addDrawerDialog.show()}>Add Camera</Button>
      )} */}
                </div>
                <Table
                    className="mt-6"
                    borderStyle="divider"
                    // hover
                    isLoading={listQueryData.isLoading}
                    data={listQueryData.listings}
                    columns={[
                        {
                            header: 'ID',
                        },
                        {
                            header: 'title',
                        },
                        {
                            header: 'overview',
                        },

                        {
                            header: 'Action',
                        },
                    ]}
                    renderRow={(row) => (
                        <Table.Row key={row._id} onClick={() => {}}>
                            <Table.Cell>
                                {
                                    <div
                                        className="text-primary cursor-pointer"
                                        // onClick={() => editUserDailog.show(row._id)}
                                    >
                                        {row._id}
                                    </div>
                                }
                            </Table.Cell>
                            <Table.Cell>{row.title}</Table.Cell>
                            <Table.Cell>{row.overview}</Table.Cell>
                            <Table.Cell>
                                <Table.Cell>
                                    <EventBlocker stopPropagation preventDefault>
                                        <button
                                            className="p-1 text-grayscale-500"
                                            // onClick={() => navigate(`/users/${row._id}`)}
                                        >
                                            <EditIcon />
                                        </button>
                                    </EventBlocker>
                                </Table.Cell>
                                {
                                    <>
                                        {/* <Dropdown placement="bottom-start" showArrow>
                                <DropdownTrigger className="w-4 h-4 rounded-full">
                                    <EllipsisVerticalIcon />
                                </DropdownTrigger>

                                <DropdownList className="min-w-[100px]">
                                    <DropdownItem onClick={() => editUserDailog.show(row._id)}>
                                        Edit
                                    </DropdownItem>

                                    <DropdownItem onClick={() => projectAccessDailog.show(row._id)}>
                                        Project Access
                                    </DropdownItem>
                                    {hasAdminPermission ? (
                                        <DropdownItem
                                            onClick={() => deleteUserDailog.show(row)}
                                            className="text-danger"
                                        >
                                            Delete
                                        </DropdownItem>
                                    ) : (
                                        <Tooltip
                                            placement="left"
                                            content={'Only Admins are authorized to delete user'}
                                        >
                                            <DropdownItem
                                                className="text-gray-300 cursor-not-allowed"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                }}
                                            >
                                                Delete
                                            </DropdownItem>
                                        </Tooltip>
                                    )}
                                </DropdownList>
                            </Dropdown> */}

                                        {/* <div className="grid gap-2 grid-cols-3">
                  <Tooltip placement="top" content={"Edit"}>
                    <button onClick={() => editUserDailog.show(row._id)}>
                      <EditIcon />
                    </button>
                  </Tooltip>

                  {hasPermission(PermissionEnum.USER_DELETE) ? (
                                    <Tooltip placement="top" content={'Delete'}>
                                        <button onClick={() => deleteUserDailog.show(row)}>
                                            <DeleteShapeIcon />
                                        </button>
                                    </Tooltip>
                                ) : (
                                    <Tooltip
                                        placement="left"
                                        content={'Only Admins are authorized to delete User'}
                                    >
                                        <button className="text-gray-300 cursor-not-allowed">
                                            <DeleteShapeIcon />
                                        </button>
                                    </Tooltip>
                                )}
                </div> */}
                                    </>
                                }
                            </Table.Cell>
                        </Table.Row>
                    )}
                />
                {/* <div className="grid grid-cols-4 gap-6 mt-6 empty:hidden">
          {dummyListings.map((listing, i) => (
            <div className="col-span-1" key={i}>
              <ListingCard
                listing={listing}
                // setConfirmationDrawer={setConfirmationDrawer}
                onEditClick={() => {}}
                onDeleteClick={(listing) => {}}
                // setEditDrawer={setEditDrawer}
                // hasPermission={hasPermission(PermissionEnum.TIMELAPSE_CAMERA_DELETE)}
              />
            </div>
          ))}
        </div> */}
            </PageTemplate>
        </div>
    );
};
export default ListingList;
