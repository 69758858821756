export const getAuthTokenFromStorage = () => {
    return localStorage.getItem('auth_token') || '';
};

export const setAuthTokenInStorage = (token: string) => {
    return localStorage.setItem('auth_token', token);
};

export const removeAuthTokenFromStorage = () => {
    return localStorage.removeItem('auth_token');
};
