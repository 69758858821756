import * as Yup from 'yup';

import { UserStatusEnum, UserTypeEnum } from '@api/enums';
import { useMutation } from '@tanstack/react-query';
import { addUser } from '@api/services/user.service';

const useUserAddForm = () => {
    const addUserMutation = useMutation((payload: any) => addUser(payload));
    const schema = Yup.object({
        name: Yup.string().trim().required('This field is required'),
        company: Yup.object({
            name: Yup.string(),
            address: Yup.string(),
        }),
        phoneNumber: Yup.number(),
        dp: Yup.mixed().nullable(),
        emailHeaderLogo: Yup.mixed().nullable(),
        roles: Yup.string().required('This field is required'),
        status: Yup.string().required('This field is required'),
    });
    const initialValues: any = {
        name: '',
        company: {
            name: '',
            address: '',
        },
        phoneNumber: '',
        whatsappNumber: '',
        dp: '',
        dpUrl: '',
        roles: UserTypeEnum.VIEWER,
        status: UserStatusEnum.ENABLED,
        // file: '',
    };

    const submit = async (values: any) => {
        // console.log('values', values);
        // const formData = new FormData();
        // formData.append('name', values.name);
        // formData.append('phoneNumber', values.phoneNumber);
        // formData.append('whatsappNumber', values.whatsappNumber);
        // formData.append('role', values.roles);
        // formData.append('status', values.status);
        // formData.append('company[name]', values.company.name);
        // formData.append('company[address]', values.company.address);
        // if (values.file) {
        //     formData.append('file', values.file);
        // }
        const { data } = await addUserMutation.mutateAsync(values);
        return data;
    };
    return {
        initialValues,
        schema,
        submit,
        isSubmitting: addUserMutation.isLoading,
    };
};

export default useUserAddForm;
