import { Button, HelpText, Label } from '@hyperflake/react-ui-library';
import { ErrorMessage, Form, Formik } from 'formik';
import { FormikInput, FormikSelect, RequiredMark } from 'modules/shared/features/formik';
import { statusOption, userTypeOption } from 'modules/users/helpers/user.util';
import useUserData from 'modules/users/hooks/useUserData';
import useUserEditForm from 'modules/users/hooks/useUserEditForm';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const UserEditForm = () => {
    const { userId } = useParams();
    const navigate = useNavigate();

    const userData = useUserData(userId);

    const userEditFormData = useUserEditForm({ user: userData.data });

    if (userData.isLoading) return <h1>Loading</h1>;
    // console.log('parama', userId);

    const handleSubmit = async (values: any) => {
        try {
            const data = await userEditFormData.submit(values);
            toast.success('User Updated Successfully');
            navigate('/users');
        } catch (e: any) {
            console.log(e);
            toast.error('Oops something went wrong.');
        }
    };
    return (
        <Formik
            initialValues={userEditFormData.initialValues}
            //enableReinitialize
            validationSchema={userEditFormData.schema}
            // validateOnChange={false}
            onSubmit={handleSubmit}
        >
            {({ setFieldValue, values, isValidating }) => (
                <Form>
                    <div className="flex flex-col items-start">
                        {/* <Label>User DP</Label> */}

                        {/* <div className="flex items-center gap-4 mt-2">
                            <div className="relative w-20 h-20 bg-grayscale-200 rounded-full">
                                {values.file && (
                                    <>
                                        <img
                                            className="w-full h-full rounded-full "
                                            src={URL.createObjectURL(values.file)}
                                            alt=""
                                        />
                                        <div
                                            className="absolute top-0 left-0 w-full h-full rounded-full flex items-center justify-center text-xs font-semibold text-white bg-black/40 cursor-pointer select-none transition-opacity duration-300 opacity-0 hover:opacity-100"
                                            onClick={() => setFieldValue('file', null)}
                                        >
                                            Remove
                                        </div>
                                    </>
                                )}
                            </div>

                            <div className="relative">
                                <Button variant="shaded" size="xs">
                                    <UploadIcon className="cursor-pointer" /> Upload Image
                                    <input
                                        type="file"
                                        className="absolute top-0 left-0 bottom-0 right-0 w-full h-full opacity-0"
                                        onChange={(event) => {
                                            const files = event.target.files;

                                            if (files && files.length > 0) {
                                                setFieldValue('file', files[0]);
                                            }
                                        }}
                                    />
                                </Button>
                            </div>
                        </div> */}
                    </div>
                    <div className="mt-4 ">
                        {/* name */}
                        <div className="">
                            <Label htmlFor="name">
                                Name <RequiredMark />
                            </Label>
                            <FormikInput type="text" name="name" />
                            <HelpText variant={'error'}>
                                <ErrorMessage name="name" />
                            </HelpText>
                        </div>

                        {/* phoneNumber */}
                        <div className="">
                            <Label>
                                Phone Number <RequiredMark />
                            </Label>
                            <FormikInput type="number" name="phoneNumber" />
                            <HelpText variant={'error'}>
                                <ErrorMessage name="phoneNumber" />
                            </HelpText>
                        </div>
                        {/* whatsapp Number */}
                        <div className="">
                            <Label>
                                Whatsapp Number <RequiredMark />
                            </Label>
                            <FormikInput type="number" name="whatsappNumber" />
                            <HelpText variant={'error'}>
                                <ErrorMessage name="whatsappNumber" />
                            </HelpText>
                        </div>
                        {/* Roles */}
                        <div>
                            <Label>
                                Roles <RequiredMark />
                            </Label>
                            <div className="flex gap-2">
                                <FormikSelect
                                    name="roles"
                                    options={userTypeOption}
                                    // disabled={formik.values.pointedTo !== ''}
                                />
                            </div>
                            <HelpText variant={'error'}>
                                <ErrorMessage name="role" />
                            </HelpText>
                        </div>
                        {/* status */}
                        <div>
                            <Label>
                                Status <RequiredMark />
                            </Label>
                            <div className="flex gap-2">
                                <FormikSelect
                                    name="status"
                                    options={statusOption}
                                    // disabled={formik.values.pointedTo !== ''}
                                />
                            </div>
                            <HelpText variant={'error'}>
                                <ErrorMessage name="role" />
                            </HelpText>
                        </div>

                        {/* company name */}
                        <div className="">
                            <Label>Company Name</Label>

                            <FormikInput type="text" name="company.name" />
                            <HelpText variant={'error'}>
                                <ErrorMessage name="company.name" />
                            </HelpText>
                        </div>
                        <div className="">
                            <Label>Company Address</Label>

                            <FormikInput type="text" name="company.address" />
                            <HelpText variant={'error'}>
                                <ErrorMessage name="company.address" />
                            </HelpText>
                        </div>
                    </div>

                    <Button
                        //   disabled={addUserForm.isSubmitting || isValidating}
                        type="submit"
                        size={'lg'}
                        className="mt-8 w-28"
                    >
                        {/* {addUserForm.isSubmitting ? (
      <CircularProgress size={20} color={'inherit'} />
  ) : isValidating ? (
      'Validating'
  ) : (
      'Submit'
  )} */}
                        Submit
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

export default UserEditForm;
