import { getAllListing } from '@api/services/listing.service';
import useDataQuery from 'modules/shared/hooks/use-data-query';
import React from 'react';

const useListingListData = () => {
    const listingListQuery = useDataQuery(['listing'], () => getAllListing());
    return {
        listings: listingListQuery.data,
        isLoading: listingListQuery.isLoading,
    };
};

export default useListingListData;
