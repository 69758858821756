import { getUsersById } from '@api/services/user.service';
import useDataQuery from 'modules/shared/hooks/use-data-query';

const useUserData = (userId: string) => {
    const userQuery = useDataQuery(['project'], () => getUsersById(userId), {
        enabled: Boolean(userId),
    });

    return {
        data: userQuery.data,
        isLoading: userQuery.isLoading,
    };
};
export default useUserData;
