import {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useCallback } from "react";
import { DataUpdater } from "../types/updated";

type UseDataQueryResult<TData, TError> = UseQueryResult<TData, TError> & {
  setData: (updater: DataUpdater<TData>) => void;
};

const useDataQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    "queryKey" | "queryFn" | "initialData"
  > & {
    initialData?: () => undefined;
  }
): UseDataQueryResult<TData, TError> => {
  const query = useQuery(queryKey, queryFn, options);

  const queryClient = useQueryClient();

  const setData = useCallback(
    (updater: DataUpdater<TData>) => {
      queryClient.setQueryData<TData>(queryKey, updater);
    },
    [queryClient, queryKey]
  );

  return { ...query, setData };
};

export default useDataQuery;
