import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import AppRouter from './AppRouter';

import { ToastContainer } from 'react-toastify';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import './index.scss';

function App() {
    // Create  a client
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                cacheTime: 0,
            },
        },
    });

    return (
        <>
            <QueryClientProvider client={queryClient}>
                <AppRouter />

                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
            <ToastContainer autoClose={3000} hideProgressBar={true} position={'top-left'} />
        </>
    );
}

export default App;
