import { getAllUsers } from '@api/services/user.service';
import useDataQuery from 'modules/shared/hooks/use-data-query';

const useUserListData = () => {
    const userListQuery = useDataQuery(['Users'], () => getAllUsers());

    return {
        users: userListQuery.data,
        isLoading: userListQuery.isLoading,
    };
};

export default useUserListData;
