import { UserStatusEnum, UserTypeEnum } from '@api/enums';
import { IUser } from '@api/models/user.model';
import { updateUser } from '@api/services/user.service';
import { useMutation } from '@tanstack/react-query';
import { useMemo } from 'react';
import * as Yup from 'yup';

interface userEditProps {
    user: IUser;
}

const useUserEditForm = (props: userEditProps) => {
    const { user } = props;
    const updateUsertMutation = useMutation((payload: IUser) => updateUser(user._id, payload));

    const schema = Yup.object({
        name: Yup.string().trim().required('This field is required'),
        company: Yup.object({
            name: Yup.string(),
            address: Yup.string(),
        }),
        phoneNumber: Yup.number(),
        dp: Yup.mixed().nullable(),
        emailHeaderLogo: Yup.mixed().nullable(),
        roles: Yup.string().required('This field is required'),
        status: Yup.string().required('This field is required'),
    });

    const initialValues = useMemo(() => {
        if (!user)
            return {
                _id: '',
                name: '',
                company: {
                    name: '',
                    address: '',
                },
                phoneNumber: '',
                whatsappNumber: '',
                dp: '',
                dpUrl: '',
                roles: UserTypeEnum.BROKER,
                status: UserStatusEnum.ENABLED,
                file: '',
            };

        return {
            _id: user._id,
            name: user.name,
            company: {
                name: user.company.name,
                address: user.company.address,
            },
            phoneNumber: user.phoneNumber,
            whatsappNumber: user.whatsappNumber,
            dp: user.dp,
            dpUrl: user.dpUrl,
            roles: user.roles,
            status: user.status,
            file: '',
        };
    }, [user]);

    const submit = async (payload: any) => {
        const data = await updateUsertMutation.mutateAsync(payload);

        return data;
    };

    return {
        initialValues,
        schema,
        submit,
    };
};

export default useUserEditForm;
