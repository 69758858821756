import { Button, Table } from '@hyperflake/react-ui-library';
import { EventBlocker } from '@hyperflake/react-ui-library/eventBlocker/index.js';
import RoleChip from 'modules/shared/components/RoleChip/RoleChip';
import StatusChip from 'modules/shared/components/StatusChip/StatusChip';
import { PageHeading, PageTemplate } from 'modules/shared/features/content';
import { EditIcon } from 'modules/shared/icons';
import useUserListData from 'modules/users/hooks/useUserListData';
import { useNavigate } from 'react-router-dom';

const UsersList = () => {
    const userListData = useUserListData();
    const navigate = useNavigate();

    if (userListData.isLoading) {
        return <h3>Loading</h3>;
    }

    return (
        <PageTemplate>
            <div className="flex justify-between">
                <PageHeading>Users</PageHeading>
                <Button onClick={() => navigate('/users/add')}>Add New User</Button>
            </div>
            <Table
                className="mt-6"
                borderStyle="divider"
                // hover
                isLoading={userListData.isLoading}
                data={userListData?.users}
                columns={[
                    {
                        header: 'ID',
                    },
                    {
                        header: 'Name',
                    },
                    {
                        header: 'Phone Number',
                    },
                    {
                        header: 'Role',
                    },
                    {
                        header: 'Status',
                    },
                    {
                        header: 'Action',
                    },
                ]}
                renderRow={(row) => (
                    <Table.Row key={row._id} onClick={() => {}}>
                        <Table.Cell>
                            {
                                <div
                                    className="text-primary cursor-pointer"
                                    // onClick={() => editUserDailog.show(row._id)}
                                >
                                    {row._id}
                                </div>
                            }
                        </Table.Cell>
                        <Table.Cell>{row.name}</Table.Cell>
                        <Table.Cell>{row.phoneNumber}</Table.Cell>
                        <Table.Cell>{<RoleChip status={row.roles} />}</Table.Cell>
                        <Table.Cell>{<StatusChip status={row.status} />}</Table.Cell>
                        <Table.Cell>
                            <Table.Cell>
                                <EventBlocker stopPropagation preventDefault>
                                    <button
                                        className="p-1 text-grayscale-500"
                                        onClick={() => navigate(`/users/${row._id}/edit`)}
                                    >
                                        <EditIcon />
                                    </button>
                                </EventBlocker>
                            </Table.Cell>
                        </Table.Cell>
                    </Table.Row>
                )}
            />
        </PageTemplate>
    );
};

export default UsersList;
